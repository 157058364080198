.header {
  font-size: 2.5rem;
  text-align: center;
}

.cas-seo h1,
.cas-seo h2,
.cas-seo h3 {
  font-weight: 400;
  color: #c546d3;
}

.cas-seo .accordion-button,
.cas-seo.accordion-button:not(.collapsed) {
  color: #c546d3 !important;
}

.cas-seo .content-table i {
  background: #c546d3;
}

.cas-seo .content-table i {
  background: #c546d3;
}

.subtitle {
  font-weight: 400;
  color: #79c000;
  border-radius: 0;
  padding-left: 0.75rem;
}

.seo-nav-active {
  color: #79c000;
  filter: brightness(0) saturate(100%) invert(77%) sepia(60%) saturate(4287%)
    hue-rotate(40deg) brightness(96%) contrast(101%);
}

.c-vr {
  height: 70%;
  margin-top: -5px;
  border-left: 0.5px solid #444444a1;
}

.colored-purp {
  color: #c546d3;
  filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(5243%)
    hue-rotate(261deg) brightness(83%) contrast(99%);
}

span.colored-purp {
  border-bottom: 3px solid #c546d3;
  padding-bottom: 5px;
}

.e-nav span {
  font-weight: 400;
  font-size: 0.75rem;
}

.underline {
  width: 100%;
  height: 3px;
}

.seo-nav-active .underline {
  background-color: #79c000;
}

.c-seo-nav-active {
  filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(5243%)
    hue-rotate(261deg) brightness(83%) contrast(99%);
}

.c-seo-nav-active .underline {
  background-color: #c546d3;
}

.e-nav .c-link:hover {
  filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(5243%)
    hue-rotate(261deg) brightness(83%) contrast(99%);
}
.e-nav .d-link:hover {
  filter: invert(55%) sepia(103%) saturate(1266%) hue-rotate(62deg)
    brightness(94%) contrast(77%);
}

.e-nav {
  padding: 4.6rem 0 0 0;
  background-color: #333f48;
  height: 8.5rem;
  justify-content: center;
}

.e-nav a {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  transition: opacity 75ms ease-in-out;
}

.e-nav .hoverable:hover,
.e-nav .hoverable:hover span {
  opacity: 0.5;
  transition: opacity 75ms ease-in-out;
}

.seo-nav-tab {
  min-width: 80px;
}

#eventos ul,
.event ul {
  color: #fff;
  padding-left: 0;
}

#eventos p,
.event p {
  color: #fff;
  font-size: 1.25rem;
  text-align: left;
}

.ver_btn {
  /* background-color: #79c000; */
  background-color: unset;
  border: 1px solid #79c000;
  font-weight: 400;
  border-radius: 15px;
  font-size: 1.4rem;
  padding: 0.75rem 4rem 0.75rem 4rem;
  float: left;
  transition-duration: 0.35s;
}

#eventos a h2 {
  font-size: 1.1vw;
  font-weight: 700;
  color: #79c000;
  position: relative;
  text-align: center;
}

.ver_btn:hover,
.ver_btn:focus {
  background-color: unset;
  border: none;
  /* box-shadow: inset 13em 0 0 0 #79c000; */
  box-shadow: inset 13em 0 0 0 #548801;
}

/* Card */

.eventos-container {
  max-width: 75%;
  display: flex;
  text-align: center;
}

.eventos-container .row > * {
  padding: 0;
}

.eventos_card {
  /* background-color: #252a30; */
  background-color: #323f47;
  margin: 5px;
  /* width: 50%; */
  padding: 1rem 0;
  border-radius: 0.5rem;
}

.card-header {
  padding: 0;
  background-color: unset !important;
  border-bottom: unset !important;
  text-transform: uppercase;
}

.eventos-container img {
  max-width: 100%;
  transition: all 0.5s;
}

.eventos-container img {
  filter: grayscale(55%);
  transition: all 0.5s;
}

.eventos-container :hover img {
  filter: grayscale(0);
}

.card-footer {
  border-top: none;
  padding: 0.5rem 1px;
}

.event .accordion {
  margin: 2rem 0 3rem 0;
}

.event .accordion-button {
  /* color: #fff; */
  font-weight: 600;
  font-size: 1.15rem;
}

.event .accordion-item {
  color: #fff;
}

/* Casino */
.casino-row {
  --bs-gutter-y: 1.5rem;
  justify-content: center;
  margin: 1rem 0;
}
.cas-game-wrapper {
  box-shadow: -10px 0 10px -7px #000, 10px 0 10px -7px #000,
    5px 5px 15px 5px transparent;
}

.casino-game-img {
  max-width: 100%;
  border-radius: 10px;
  transition: opacity 500ms ease-in-out;
}

.top-bg-seo {
  padding-bottom: 17.5%;
}

.cas-game-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cas-game-wrapper .hvr {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: scale(0.65);
}

.hvr {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hvr p {
  white-space: nowrap;
  text-align: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 250px;
  font-size: 1.25rem;
  font-weight: 700;
  display: none;
}

.hvr img {
  max-width: 104px;
  /* transition: opacity 0.5s; */
  /* transition: opacity 0.3s ease-in-out; */
}
.cas-game-wrapper:hover .casino-game-img {
  background: #fff;
  opacity: 0.25;
  transition: opacity 500ms ease-in-out;
}

.cas-game-wrapper:hover .hvr {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.cas-reg-btn {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0;
  border: none;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.65rem 4rem;
  text-align: center;
  max-width: 180px;
}

.shining {
  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.1) 50%,
    #000 75%
  );
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
}

.shining:hover {
  /* transition: mask-position 2s ease, -webkit-mask-position 2s ease; */
  -webkit-mask-position: 100%;
  mask-position: 100%;
  opacity: 1;
}

/*Navbar SEO pages*/

@media screen and (max-width: 767px) {
  .seo-nav-tab {
    min-width: 70px;
    height: 65px;
  }

  .e-nav .hoverable:hover,
  .e-nav .hoverable:hover span {
    opacity: 1;
  }

  .e-nav {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 31vw;
    padding: 17vw 0 0 0;
    justify-content: space-evenly;
  }

  .c-vr {
    height: 13vw;
    border: 1px solid #4444446a;
  }

  .e-nav img {
    width: 30px;
    height: 30px;
  }

  .header {
    text-align: left;
    font-size: 1.55rem;
  }

  #eventos h1 {
    padding: 0 0.25rem 0 0.25rem;
    /* margin-bottom: 1.75rem; */
  }

  #eventos a h2 {
    font-size: 1rem;
  }

  /* Card */
  .eventos-container {
    max-width: 100%;
    display: block;
  }

  .eventos-container h2,
  .eventos-container p,
  .ver_btn {
    text-align: center;
    float: none;
  }

  .eventos_card {
    width: 100%;
    margin: auto;
    padding: 0.35rem 0;
  }

  .eventos-container .row > * {
    padding: 0.25rem;
  }

  .eventos_card .btn {
    padding: 0.475rem 0.1rem;
    font-size: 0.6rem;
  }

  .card-body {
    padding: 0.25rem;
  }

  .event .accordion {
    width: 100%;
  }

  /* Casino */
  .casino-row {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
    margin: 0;
  }

  .top-bg-seo {
    padding-bottom: 50%;
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .cas-reg-btn {
    max-width: 42.5vw;
    margin: 6vw auto;
    padding: 2vw 0;
  }

  .e-nav span {
    font-size: 11px;
    padding: 0 7.5px;
  }
}

@media (min-width: 321px) and (max-width: 424px) {
  .e-nav {
    height: 34vw;
  }
}
@media (max-width: 320px) {
  .content-table {
    padding: 0.75rem;
  }
  .e-nav {
    height: 37vw;
    padding: 18vw 0 0 0;
  }
  .e-nav a svg {
    font-size: 1.75rem !important;
  }

  .header {
    font-size: 1.15rem !important;
  }
}

.floating {
  color: #fff;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  margin-top: 1vw;
  width: 25%;
  z-index: 2;
}

.floating span {
  font-family: Codere;
  font-size: 4.5vw;
  line-height: 1;
}

.floating a {
  background-color: #0056fe;
  margin-top: 0.75vw;
  font-weight: 700;
  font-size: 1.65rem;
  border-radius: 8px;
  padding: 0.55vw 2.15rem;
}

.seotable {
  width: 100%;
  margin: unset !important;
}

.content-table {
  margin: auto;
  color: #fff;
  padding: 1.25rem;
  width: 100%;
  /* box-shadow: #7ac00049 0px 2px 6px, #7ac00017 0px 1px 4px,
      #7ac00013 0px 0px 12px; */
  background-color: #333;
  border-radius: 0.45rem;
  overflow: auto;
}

.content-table i {
  background: #79c000;
  border-radius: 2.5px;
  display: inline-block;
  height: 9px;
  width: 9px;
  margin-right: 10px;
}

.content-table .subtitle {
  border-left: 3px solid #79c000;
  border-radius: 0;
  padding-left: 0.75rem;
  color: #79c000;
  margin-left: 1.5rem;
}

.flexed-centered {
  display: flex;
  align-items: center;
}

.container > div > ._tx.flexed-centered,
.container > ._tx.flexed-centered {
  width: 48%;
  float: left;
}

.container.formulaTable > ._tx.flexed-centered {
  width: 100%;
}

.tabla {
  display: flex;
}

._tx {
  margin: 0.35rem;
}

._tx a {
  text-decoration: underline;
  color: #fff;
}
._tx {
  transition: all 0.3s ease;
}

.content-table a:hover,
.content-table a:active,
.content-table a:focus {
  color: #4b7502 !important;
}

.cas-seo .content-table a:hover,
.cas-seo .content-table a:active,
.cas-seo .content-table a:focus {
  color: #c546d3 !important;
}

#tipos h2,
#tipos h3 {
  color: #79c000;
}

.para {
  color: #79c000;
  padding-top: 4.1rem;
}

.h3,
h3 {
  font-size: 1.4rem;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  color: #79c000;
}

@media screen and (max-width: 767px) {
  .e-nav a {
    gap: 6px;
  }
  .cas-seo h1 {
    text-align: center;
  }

  .h3,
  h3 {
    font-size: calc(1.1rem + 0.6vw);
  }

  .floating {
    text-align: center;
    display: flex;
    height: 49vw;
    width: 62%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .floating span {
    font-size: 10.2vw !important;
    line-height: 1;
  }

  .floating a {
    margin-top: 1.15rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.55rem 1.65rem;
    line-height: 1;
  }

  .content-table {
    width: 100%;
    display: block;
    max-width: 450px;
    min-width: 300px;
    padding: 1.75rem;
    display: none;
  }
}
