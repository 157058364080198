/* For LOCAL env */
@font-face {
  font-family: Codere;
  src: url(./Fonts/HighSchool-V3.1.ttf);
}
/* For PRODUCTION */
@font-face {
  font-family: Codere;
  src: url(https://www.codere.com.co/Fonts/HighSchool-V3.1.c78c7153ed9023134905.ttf);
  font-display: fallback;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  overflow-x: hidden !important;
  font-family: "Roboto";
}
body {
  background-color: #252a30;
}
html,
body {
  overflow-x: hidden !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(
    0deg,
    rgba(101, 192, 0, 1) 20%,
    rgba(117, 189, 1, 1) 20%
  );
  opacity: 0.2;
  height: 190px;
}
/* Cookie Consent */
.cookie-content h3,
.cookie-content a {
  color: #79c300;
}
.cookie-content {
  opacity: 0.9;
  flex: 0.75 0 300px !important;
  margin: 15px 0 !important;
}
.cookie-btn {
  background-color: #79c000 !important;
  border-radius: 4px !important;
  font-weight: 600;
  width: 250px;
  transition: all 300ms ease;
  color: white !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}

.cookie-btn:hover {
  background-color: #79c000cd !important;
  transition: all 300ms ease;
}

.seo a {
  color: #79c000;
}

.seo a:hover {
  color: #79c000;
}

.cookie-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.cookie-container {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  flex-wrap: wrap !important;
  align-items: flex-end !important;
  justify-content: space-evenly !important;
}

.navbar-nav {
  margin-left: 6% !important;
}

/* stroke */
.stroke .navbar-nav a,
.stroke .navbar-nav a::after,
.stroke .navbar-nav a::before {
  transition: all 0.5s;
}

.stroke .navbar-nav a {
  position: relative;
}

.stroke .navbar-nav a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #fff;
  height: 2px;
  border-radius: 5px;
}

.stroke .navbar-nav a:hover::after {
  width: 100%;
}

/* End Stroke */

.header_nav {
  background-color: #252a30;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 7px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-size: 0.9vw;
}

.navbar {
  position: fixed;
  width: 100%;
  justify-content: center;
  z-index: 9;
  height: 66px;
}

.navbar-dark > div {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: initial;
  width: 100%;
}

.header_nav img {
  position: relative;
  width: 9.5rem;
  margin-right: 1vw;
  left: 0;
  cursor: pointer;
}

/* DropDown */
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: unset;
  border-color: unset;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: unset;
}

.dropdown button {
  background: 0 0;
  border: unset;
  color: #000;

  /* font-size: 1vw; */
}

.dropdown .svg-inline--fa {
  height: 1.5vw;
  position: relative;
  top: 0.2vw;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown button:hover,
.dropdown button:active {
  background: 0 0 !important;
  color: #fff;
  border: unset !important;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0.4vw;
  left: -5vw;
}

.dropdown-menu {
  width: 15rem;
  background-color: #212529db;
  text-align: center;

  /* padding: 0.1vw 0.1vw 0 0.1vw; */
  margin: auto;

  /* border-radius: 0 0 1vw 1vw; */
}

.dropdown-item {
  margin: auto;
  color: #fff;
  border-bottom: #ffffff73 0.5px solid;
  padding: 0.55rem 0;
  text-transform: capitalize;
  width: 80%;
}

.dropdown a:first-child {
  padding-top: 5px;
}

.dropdown a:last-child {
  border: unset;
  padding-bottom: 5px;
}

.dropdown-item:hover,
.dropdown-item:active {
  font-weight: 600;
  background-color: #e9ecef;
  color: #000;
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.9s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.show .dropdown-menu {
  max-height: 33rem;
  opacity: 1;
}

/* End DropDown */

.acceder-button {
  background-color: #79c000;
  border: initial;
}

.acceder-button:hover,
.acceder-button:active {
  background-color: #79c000cc !important;
}

.registrate-button:active,
.registrate-button:focus {
  background-color: #0d6efdcc !important;
}

.acceder-button,
.registrate-button {
  border: unset;
  border-radius: 4px;
  width: 120px;
  font-size: 1.15rem;
  border: unset;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  /* width: 110px; */
  padding: 5px 15px;
  font-size: 1.15rem;
}

.central-regis,
.central-regis-enc,
.bottom-game-regis {
  border-radius: 8px;
  font-size: 1rem;
  padding: 17px 11px;
  margin: 0;
  margin-left: 0.5vw;
  font-weight: bold;
  max-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #0587f5, #0263b5);
}

.central-regis:hover {
  background: linear-gradient(180deg, #0263b5, #0587f5);
}

.slidebtn {
  position: relative;
  top: -10vw;
  left: 16vw;
  width: 18%;
  text-align: center;
}

.terms {
  /* position: absolute;
    left: 3vw; */
  margin-top: 0.6vw;
  color: white;
}

.mob-tyc {
  text-align: center;
}

.mob-tyc span {
  position: relative;
  bottom: 10vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
}

.tyc {
  color: #fff;
  cursor: pointer;
}

.tyc:visited {
  color: #fff;
}

.tyc:hover {
  color: #79c000;
  text-decoration: none;
}

#des-reg {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 19vw;
  z-index: 2;
  top: -10vw;
  left: 15.7vw;
  text-align: center;
}

.central-regis {
  font-size: 1.7vw;
  border-radius: 8px;
  border: unset;
  padding: 10px 0 10px 0;
  z-index: 1;
  border-color: rgba(227, 227, 227, 0.63);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: unset;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.jugar-div {
  position: sticky;
  top: 15.75rem;
}

.jugar-regis {
  font-size: 1.1vw;
  width: 11vw;
  background-color: #c546d3;
  border: unset;
}
.jugar-regis:hover,
.jugar-regis:active,
.jugar-regis:focus {
  background-color: #c546d3aa !important;
}

@keyframes glowing {
  0% {
    background-color: "#0055fe0";
    -webkit-box-shadow: 0 0 4px #fff;
  }

  50% {
    background-color: "#0055fe0";
    -webkit-box-shadow: 0 0 7px #fff;
  }

  100% {
    background-color: "#0055fe0";
    -webkit-box-shadow: 0 0 3px #fff;
  }
}

.central-regis svg {
  margin-top: 0.25vw;
  margin-left: 0.2vw;
}

.central-regis:hover svg {
  animation: sds2 0.8s infinite;
}

/* END TEST */
.login_btn {
  margin: auto;
  text-align: center;
  background-color: #79c000;
  border: #79c000;
  width: 65%;
  margin-bottom: 5vw;
  padding: 1em;
  border-radius: 20px;
}

.form-control {
  width: 65%;
  text-align: center;
  font-size: 20px;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-color: #79c000;
  margin: auto;
  padding: 0.7vw;
  border-radius: 20px;
}

.modal-content {
  /* background-color: rgb(148, 147, 147); */
  border-radius: 8px;
}

.terms-tyc {
  background-color: rgb(255, 255, 255);
  color: #000;
  overflow-x: hidden;
  max-width: 450px;
}

.modal-body {
  overflow-x: hidden;
}

.terms-tyc .modal-body table {
  overflow-x: auto !important;
}

.terms-tyc .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.terms-tyc .modal-title {
  margin: auto;
}

.terms-tyc .modal-title img {
  width: 75px;
}

.terms-tyc .modal-header,
.terms-tyc .modal-footer {
  border: unset;
}

.terms-tyc .test {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.terms-tyc ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.terms-tyc ol {
  font-size: 0.75rem;
  counter-reset: my-counter;
  list-style-type: none;
  padding-left: 0;
}

.terms-tyc ol > li {
  counter-increment: my-counter;
  padding-left: 25px;
  position: relative;
  color: #000;
}
.terms-tyc ul > li {
  color: #000;
}
.terms-tyc ol > li::before {
  content: counter(my-counter) ". ";
  color: #000;
  position: absolute;
  left: 0;
}

.modal-header .btn-close {
  padding: 0;
  margin: 0;
}

.table > :not(caption) > * > * {
  margin-left: 1rem;
}

.terms-tyc .modal-body {
  padding: 0.25rem 1rem;
}

.terms-tyc .accept-btn {
  background-color: #79c000;
  border: #79c000;
  border-radius: 6px;
  width: 100%;
  font-size: 1.15rem;
  font-weight: 700;
}

/* .btn-close:hover {
  transform: rotate(360deg);
  transition: transform 600ms ease-out;
} */

/* .modal-header .btn-close {
  padding: 0;
  margin: 0;
} */

#modal_app {
  text-align: center;
}

#carousel-section {
  padding-top: 4rem;
  min-height: 90vh;
}

/* Swiper */

#carousel-section .swiper-slide img,
.game-carousel .swiper-slide img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.game-carousel .swiper-slide img {
  border-radius: 10px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 4vw !important;
}

.swiper-pagination-bullet-active {
  background-color: #79c000 !important;
}

.swiper-pagination-bullet {
  width: 11px;
  height: 11px;
}

/* GAMES */
#desktop_games {
  text-align: center;

  /* margin-top: 4vw; */
}

#desktop_games h2 {
  /* text-align: center; */
  color: #fff;
  font-size: 1.4vw;
  color: #e8e5e5;
  font-weight: 700;
  margin-bottom: 1vw;
  letter-spacing: 1px;
}

.top_new_0 img,
.top_new_1 img,
.top_new_2 img {
  position: relative;
  width: 15rem;
  height: auto !important;
}
.top_new_0 img:hover,
.top_new_1 img:hover,
.top_new_2 img:hover {
  transform: scale(1.2);
  transition: all 125ms ease-in;
}
.top_new_0 img {
  top: -2rem;
}
.top_new_1 img {
  top: 0;
}

.top_new_2 img {
  top: -3rem;
}

.portrait_container {
  display: inline-flex;
  grid-template-columns: auto auto 0;
  grid-gap: 3rem;

  /* padding: 2rem 0 4rem; */
}

.portrait_container .landscapeGame {
  margin: auto;
  width: 35vw;
}

.landscapeGameImage,
.game_logo {
  transition: 0.3s;
}

.portrait_container .landscapeGame:hover .landscapeGameImage {
  transform: scale(0.95);
}

.portrait_container .card {
  background-color: #26363f;
  border-radius: 40px;
  height: 25vw;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  border-radius: 16px;
}

img.landscapeGameImage {
  position: absolute;
  width: 35vw;
  top: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.78);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.78);
}

img.game_logo {
  margin: auto;
  width: 50%;
  position: relative;
  top: 3vw;
  z-index: 1;
}

.portrait_container .landscapeGame:nth-child(2) .game_logo {
  top: 5vw;
}

img.game_logo:hover {
  transform: scale(1.15);
}

.game_info {
  display: inline-flex;
  bottom: 1vw;
  position: absolute;
  text-align: left;
  border-left: 4px #fff solid;
  border-radius: 1px;
  color: #fff;
  padding-left: 1vw;
  margin-left: 1vw;
}

.landscapeGameTitle {
  font-size: 1.5vw;
  font-weight: 700;
  letter-spacing: -1px;
  width: 19vw;
}

/* Botttom Games */
.portrait_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}

.portrait_container h3 {
  margin-top: 6px;
  color: #fff;
  font-size: 1.3vw;
}

.bottom-box .game img {
  width: 26rem;
  border-radius: 15px;
}

.bottom-box .logo img {
  height: 5vw;
}

.portrait_container > div:nth-child(2) > div.bottom-box > div.logo {
  top: 0;
}

.portrait_container :nth-child(3) .logo img {
  height: 10vw;
}

.portrait_container .bottom-card {
  position: relative;
  min-width: 20vw;
  height: 27vw;
  box-shadow: inset 5px 5px 5px rgb(0 0 0 / 20%),
    inset -5px -5px 15px rgb(255 255 255 / 10%), 5px 5px 15px rgb(0 0 0 / 30%),
    -5px -5px 15px rgb(255 255 255 / 10%);
  border-radius: 15px;
  margin: 25px 0;
  transition: 0.5s;
}

/* .bottom-content a {
  background: #2196f3;
} */

.portrait_container .bottom-card .bottom-box {
  position: absolute;
  height: 25vw;
  top: -0.3vw;
  left: 15px;
  right: 15px;
  bottom: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.portrait_container .bottom-card:hover .bottom-box {
  /* transform: translateY(-80px); */
  transform: scale(0.9) translateY(-3vw);
}

.portrait_container .bottom-card .bottom-box .bottom-content {
  padding: 10px;
  text-align: center;
}

.portrait_container .bottom-card .bottom-box .bottom-content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.portrait_container .bottom-card .bottom-box .bottom-content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.portrait_container > a:nth-child(2) .logo {
  top: 0;
}

.bottom-box .logo {
  position: absolute;

  /* top: 0;
    left: 0; */
}

.portrait_container .bottom-card .bottom-box .bottom-content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.bottom-content span {
  z-index: -1;
  position: relative;
  top: 21vw;
  display: inline-block;
  font-size: 1.6vw;
  padding: 6px 20px 6px;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.bottom-game-title {
  position: relative;
  top: 21.5vw;
}

.portrait_container .bottom-card .bottom-box .bottom-content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.bottom-content .svg-inline--fa {
  top: 0;
  height: 0.8em;
  left: 5px;
}

#swipe-down {
  color: #fff;
  top: -10vw;
  left: 48%;
  width: 30px;
  height: auto;
  z-index: 999;
  border-radius: 50vw;
  border: 5px #fff solid;
  padding: 12px;
  cursor: pointer;
  animation-name: upanddown;
  animation-duration: 1s;
  position: relative;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes upanddown {
  0% {
    transform: translateY(0.4rem);
  }

  50% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0.4rem);
  }
}

/* Content */
.content-container {
  margin: auto;
  color: #fff;
  width: 80%;
}

.bottomContentLink {
  text-decoration: none;
  color: #79c000;
}

.bottomContentLink:visited {
  color: #79c000;
}

#acc-section > p a:visited {
  color: #79c000;
}

h1.bottomContentSubTitle {
  font-size: xx-large;
}

.gamesTitle {
  text-align: center;
  color: #79c000;
  text-transform: capitalize;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 500;
}

.bottomContentSubTitle {
  color: #79c000;
}

.content-container .row {
  justify-content: center;
}

.content-container .row .col:nth-child(2),
.content-container .row .col:nth-child(3) {
  border-left: 2px #79c000 solid;
}

.apuestas {
  margin: auto;
  width: 23rem;

  /* margin-bottom: 2vw; */
}

.apuestas .col {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.bottomContentTitle {
  color: #79c000;
}

.left-col,
.middle-col,
.right-col {
  width: 30%;
  padding: 1.2em 1.2em 0 1.2em;
  border-radius: 25px;

  /* background-color: rgb(68, 68, 68); */

  margin: 1px;

  /* -webkit-box-shadow: 1px 2px 11px 0px rgba(121, 192, 0, 0.51);
    box-shadow: 1px 2px 11px 0px rgba(121, 192, 0, 0.51); */
}

.responsive-table {
  width: 972px;
}

/* Table */
.table {
  margin: auto;
  margin-bottom: 1rem;
}

.table > tbody {
  vertical-align: middle;
}

/* Table End  */

.header-group-left {
  display: flex;
  position: relative;
  align-items: center;
  left: 30px;
}
.header-group-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 10px;
}

/* Tabs */

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  background: #252a30;
  color: #fff;
  font-weight: 600;

  /* text-decoration: underline dashed #fff 1px; */
  border-right: #79c000 0.5px solid;
  border-left: #79c000 0.5px solid;
  border-radius: 0;

  /* border-top: #79c000; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: unset;
  border-color: transparent;
  color: #fff;

  /* border-top-color: #79c000; */

  border-bottom-color: #79c000;
  text-decoration: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom-color: #79c300;
  border-color: #252a30 #252a30 #79c300 #252a30;
  border-radius: 8px;
  text-decoration: none;
}

.inicioPageTextData h1,
.inicioPageTextData h2,
.inicioPageTextData h3,
.inicioPageTextData a,
.inicioPageTextData a:hover {
  color: #79c000;
  line-height: 1.1;
}
.inicioPageTextData a.registrate-button {
  color: #fff;
  max-width: max-content;
}
.seo-reg-btn {
  border-radius: 30px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 1.55rem;
  font-weight: 600;
  padding: 4px 25px;
  max-width: 200px;
  background: linear-gradient(180deg, #0587f5, #0263b5);
  color: #ffffff !important;
}

.seo-questions {
  margin: auto;
  width: fit-content;
}

.tab-content > .active {
  text-align: left;
  margin: auto;
  width: 64%;
}

/* End Tabs */

/* Segmented */

.cursor-pointer {
  cursor: pointer;
}

.my-card {
  min-height: 130px;
  display: flex;
  justify-content: center;
  background-color: #000;
  transition: all 150ms ease-in-out;
  cursor: grab;
}

.m-t-b-1 {
  margin: 5px 0;
}

.names .eleps {
  width: 80px;
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}

.elepsis-50 {
  width: 50%;
  align-items: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pad-l-r {
  padding: 0 0.5rem;
}

.fs-sm {
  font-size: 0.8rem;
}

.card .odds {
  background-color: #333f48;
  width: 100%;
  cursor: pointer;
}

.card .odds:hover {
  background-color: #79c000aa;
  transition: all 400ms ease-in-out;
}

.gap-5 {
  gap: 5px;
}

#sportgames .swiper-slide:first-child {
  /* transform: scale(1.5); */
  box-shadow: rgba(130, 124, 124, 0.35) 50px 0px 36px -18px inset;
  border-radius: 10px;
}
#sportgames .swiper-slide:last-child {
  /* transform: scale(1.5); */
  box-shadow: rgba(130, 124, 124, 0.35) -50px 0px 36px -18px inset;
  border-radius: 10px;
}

.segmented-wrapper {
  background-color: #00000015;
  padding: 8px 10px;
}

.segmented_col {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
  margin: 10px auto;
}

.codere-green {
  color: #79c000;
}
.grey {
  color: #efefef;
}

.segmented-des-reg {
  top: -7vw !important;
  left: 18.4vw !important;
}

.swiper-button-next {
  right: 0 !important;
}
.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-next,
.swiper-button-prev {
  padding: 10px !important;

  color: #fff !important;
  background-color: rgb(37, 42, 48, 0.7);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 18px !important;
  font-weight: 700;
}

.segmented-button {
  width: 200px;
  border: 2px #79c000 solid;
  background-color: unset;
  font-weight: 500;
  padding: 5px 25px;
}
.segmented-button:hover,
.segmented-button:active,
.segmented-button:focus {
  box-shadow: inset 13em 0 0 0 #79c000;
  transition: all 450ms ease-in-out;
  border: 2px #79c000 solid;
  background-color: unset;
  color: #000000;
}

/* Content Accordion */
#acc-section {
  width: 70%;
  margin: auto;
}

#acc-section h2 {
  font-size: 1.8rem;
}

.accordion-body {
  color: #fff;
}

.accordion-body ul {
  padding-left: 1rem;
}

.content-accordion .accordion-button:not(.collapsed),
.content-accordion .accordion-button {
  color: #79c000;
  background-color: unset;
}

.accordion-button:not(.collapsed),
.accordion-button {
  background-color: unset;
}

.accordion-header,
.accordion-item {
  background-color: unset;
}

.accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.hamb-accordion {
  color: #fff;
}

/* .hamb-accordion .accordion-item {
    border: unset;
} */

.hamb-accordion .accordion-button:not(.collapsed),
.hamb-accordion .accordion-button {
  color: #fff;
  font-size: 5vw;
  border-bottom: #fff 1px solid;
  border: unset;
  box-shadow: none;
}

.hamb-accordion .accordion-body {
  padding: 0;
}

.hamb-accordion .accordion-body li {
  padding-top: 0.25rem;
}

.hamb-accordion .accordion-body li a {
  width: 100%;
  display: block;
}

/* End Accordion */

.bottomContentSpecialTitle {
  color: #79c000;
  font-size: 1.5vw;
  line-height: 2vw;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.bottomContentPromoButton {
  animation: glowingSEO 1.5s infinite;
  background: linear-gradient(180deg, #0587f5, #0057a0) !important;
  border: 0.15vw solid #e3e3e3a1 !important;
  border-radius: 70px;
  color: #fff;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  margin: auto auto;
  padding: 0.5vw 0;
  text-align: center;
  width: 14vw;
}

.bottomContentSeperator {
  background: url(https://ecdn.speedsize.com/a8158cbe-3d61-41ee-bbfa-6e8051dcc86d/https://www.codere.com.co/_catalogs/masterpage/codere/images/Splash/seoSeperator.svg);
  background-size: 100% 100%;
  display: block;
  height: 0.5vw;
  margin: 1vw auto;
  width: 100%;
}

.fa-comments,
.fa-headset {
  color: #252a30;
}

.tg78dRea {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 50%;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 30px;
}

.payments {
  display: flex;
  margin: auto;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 750px;
}

.mobiles img {
  max-height: 55px;
}

.logos img {
  margin: 5px;
  max-height: 55px;
}
.payments img {
  max-height: 55px;
  border-radius: 5px;
}
.footerList34u {
  color: #fff;
  list-style: initial;
}

hr {
  margin: auto;
  margin-top: 3vw;
  border-top: 4px solid #79c000;
  width: 55%;
}

footer.nb-footer .about {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 1170px;
  text-align: center;
}

footer.nb-footer .about .social-media {
  margin-top: 10px;
}

.sponsor {
  text-align: center;
  padding-top: 3rem;
}

.sponsor img {
  width: 9rem;
  margin: 4px;
}

.methods {
  text-align: center;
}

.methods img {
  /* width: 7rem; */
  height: 3rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-right: 3px solid #79c000;
}

.methods img:last-child {
  border-right: unset;
  width: 9rem;
  height: auto;
}

.about .social-media a {
  align-items: center;
  background-color: #fff;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  box-shadow: 0 0 5px 1px hsla(0, 0%, 100%, 0.71);
  color: #79c000;
  display: flex;
  flex-wrap: nowrap;
  font-size: 25px;
  height: 46px;
  justify-content: center;
  line-height: 45px;
  text-decoration: none;
  width: 46px;
}

.social-media a svg {
  width: 2rem;
}

.whatsapp:hover {
  color: #79c000;
}

footer.nb-footer .about .social-media ul li a:hover {
  background: #fff;
  color: #fff;
}

footer.nb-footer .footer-info-single {
  margin-top: 30px;
}

footer.nb-footer .footer-info-single .title {
  color: #79c000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

footer.nb-footer .footer-info-single ul li a {
  display: block;
  color: #fff;
  padding: 2px 0;
  text-decoration: none;
  font-weight: 400;
}

footer.nb-footer .footer-info-single ul li a:hover {
  color: #79c000;
}

footer.nb-footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
}

.legals-logos {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.legals-logos img {
  min-height: 40px;
  max-height: 40px;
}

.legals-container {
  display: flex;
  flex-direction: column;
}

.aenor-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.aenor-wrapper img {
  width: 50px;
}

#legals {
  margin: auto;
  display: flex;
  font-size: 0.7vw;
  text-align: left;
  color: #a1a4a6;
  justify-content: center;
  align-items: center;
}
#legals img {
  max-height: 35px;
}
/* End Footer */

/* Mobile */
@media screen and (max-width: 475px) {
  .names .eleps {
    width: 62%;
    display: unset;
    min-height: unset;
    /* white-space: nowrap; */
  }
  .names {
    height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
  .inicioPageTextData #acc-section h2,
  .inicioPageTextData h3 {
    font-size: calc(1.3rem + 0.6vw);
    line-height: 1.25;
  }
  .inicioPageTextData h1 {
    font-size: calc(1.375rem + 1.5vw);
    line-height: 1.25;
  }
  .inicioPageTextData h2 {
    font-size: calc(1.325rem + 0.9vw);
    line-height: 1.25;
  }
  .seo p,
  .seo ul,
  .seo ol {
    font-size: 1.1rem;
    line-height: normal;
  }

  .seo h1,
  .seo h2,
  .seo h3 {
    font-size: 1.1rem;
    line-height: normal;
  }

  /* Segmented */

  #sportgames .swiper-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .segmented_col {
    text-align: center;
  }

  .segmented-button {
    margin: auto;
  }

  .responsive-table {
    width: 90%;
  }
  /* Cookie */
  .cookie-btn {
    width: 92vw;
    margin: 0 !important;
  }

  /* End Cookie */

  .mobiles,
  .logos {
    margin: 0 15px;
    justify-content: space-around;
  }

  .payments img {
    height: 10vw;
  }

  .mobiles img {
    max-height: 50px;
    vertical-align: inherit;
  }

  .logos img {
    max-height: 55px;
  }

  .logoBottomImgCodereLogoSP {
    width: 70%;
  }

  .content-container {
    width: 95%;
    margin: 1rem auto;
    padding: 3.5vw;
  }

  .content-container .row {
    margin: auto;
  }

  .left-col,
  .middle-col,
  .right-col {
    padding: 0;
  }

  .modal-dialog {
    margin: auto;
    width: 84%;
  }

  .form-control {
    width: 85%;
  }

  /* Buttons Mobile*/
  .acceder-button,
  .registrate-button {
    font-size: 3.5vw;
    width: 20vw;
    display: flex;
    justify-content: center;
  }

  /* End Buttons Mobile */

  .header_nav img {
    width: 33vw;
    height: auto;
    margin-left: 3vw;
  }

  .header_nav {
    width: 100%;
  }

  #header_buttons {
    display: flex;
    gap: 0.5rem;
  }

  #father {
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    float: right;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-right: 10px; */
  }

  .header-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;
  }

  .bm-item-list a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 1vw 1.5vw;
    padding-top: 3vw;
  }
  #father a svg {
    height: 1.45em !important;
  }

  #father a svg:first-child {
    margin-right: 3vw;
  }

  /* Hamburger */

  /* Position and sizing of burger button */
  nav ul,
  nav li {
    list-style: none;
  }

  .bm-burger-button {
    position: absolute;
    width: 28px;
    height: 20px;
    left: 3vw;
    top: 26px;
  }

  .bm-menu-wrap,
  .bm-overlay {
    top: 0;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    opacity: 1 !important;
    background: #79c000;
    border-radius: 60px;
  }

  .casino-burger .bm-burger-bars {
    background: #c546d3;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 12px;
    width: 12px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: rgba(50, 63, 72);
    padding: 0 0.7em 0 1.1em;
    font-size: 1.15em;
    width: 181px !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgba(50, 63, 72);
  }

  #elastic,
  #outer-container .bm-overlay {
    /* position: absolute !important; */
    height: 100% !important;
    top: 66px !important;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding-top: 0.8em; */
    width: 182%;
    overflow-y: auto;
  }
  #elastic .accordion-body {
    height: 75vh;
  }

  .bm-menu .fa-angle-right {
    float: right;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  .bm-item:focus-visible,
  .bm-item:focus {
    outline: unset;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    left: 0;
  }

  .bm-burger-button > span > span:nth-child(1) {
    width: 90%;
    transition: transform 100ms;
  }

  .bm-burger-button > span > span:nth-child(2) {
    width: 90%;
    transition: transform 100ms;
  }

  .bm-burger-button > span > span:nth-child(3) {
    width: 70%;
    transition: transform 100ms;
  }

  .open > span > span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: transform 700ms;
  }

  .open > span > span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: transform 400ms;
  }

  .open > span > span:nth-child(1) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }

  .open > span > span:nth-child(2) {
    /* top: 0vw !important; */
    width: 0%;

    /* left: 5%; */
  }

  .open > span > span:nth-child(3) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }

  /* End Hamburger */
  .navbar {
    height: 66px;
  }

  .mob-tyc {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    font-size: 4vw;
  }

  .mob-tyc span {
    bottom: 12vw;
  }

  #carousel-section {
    padding-top: 66px;
    min-height: unset;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
  }

  /* Games */

  #mobile-games-section {
    margin-top: -40px;
  }
  .portrait_container {
    display: block;
    padding: 0.5rem;
  }

  .portrait_container .landscapeGame {
    width: auto;
  }

  .portrait_container .card {
    height: 65vw;
    margin-bottom: 1vw;
  }

  img.landscapeGameImage {
    width: 100%;
  }

  .landscapeGameTitle {
    font-size: 4.5vw;
    width: 60vw;
  }

  .jugar-regis {
    /* top: 0.7rem; */
    width: 22vw;
    float: right;
    font-size: 3.2vw;
    position: relative;
  }

  .swiper-pagination {
    bottom: 15vw !important;
  }

  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 10px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 10px)
    ) !important;
  }

  /* End Games  */

  /* Content */
  .content-container .col-sm-4 {
    margin: 0;
  }

  .left-col,
  .middle-col,
  .right-col {
    width: auto;
  }

  .apuestas {
    width: auto;
    margin-bottom: 3vw;
  }

  .apuestas .col {
    padding-left: 5vw;
  }

  .bottomContentSpecialTitle {
    font-size: 7vw;
    line-height: 7vw;
  }

  /* End Content */

  /* ------------------- */

  /*  Sticky Footer */
  .sticky-footer-contatiner {
    text-align: center;
    position: fixed;
    display: flex;
    bottom: 0;
    background-color: #252a30;
    height: 15vw;
    width: 100%;
    z-index: 6;
    padding: 2.5vw;
    flex-direction: row;
    justify-content: space-between;
  }

  .sticky-item {
    display: flex;
    font-size: 4vw;
    padding: 1.5vw 0.5vw 1.5vw 0.5vw;

    /* box-shadow: 0 0 1px 1vw #79c000; */
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    width: 33vw;
    background: #79c000;
    border-radius: 19px;
    margin-left: 1vw;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 75%);
    border: none;
    justify-content: space-around;
  }

  .sticky-item:hover {
    color: #fff;
  }

  /* End Sticky Footer */

  /* ------------------- */

  /* Footer */
  footer .row {
    margin-left: 0.2vw;
  }

  footer {
    margin-bottom: 20vw;
  }

  .sponsor {
    padding-top: 0;
    padding-bottom: 3rem;
  }

  .methods {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .methods img {
    border: unset;
    height: auto;
    width: 10rem;
  }

  #root
    > div
    > div:nth-child(5)
    > footer
    > div.row.lass7858a
    > div.col-.fd089
    > div {
    padding-right: 3vw;
  }

  span.legalFooter {
    display: block;
    width: 96%;
  }
  .fd089 img {
    width: 14vw;
  }

  #legals img {
    margin-left: 2vw;
    margin-right: 3vw;
    width: 10vw;
  }
  .legalFooter > a {
    display: block;
    font-size: 0.9rem;
    line-height: initial;
    margin: 1rem 0;
  }

  .legalFooter > a {
    font-size: 0.8rem;
    margin: 1rem 0;
    display: block;
  }

  /* End Footer */
  #acc-section {
    width: 100%;
  }
}
.responsive-picture {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 767px) {
  .top-bg-seo {
    padding-bottom: 50%;
  }

  /*  Sticky Footer */
  .sticky-footer-contatiner {
    text-align: center;
    position: fixed;
    display: flex;
    bottom: 0vw;
    background-color: #252a30;
    height: 15vw;
    width: 100%;
    z-index: 6;
    padding: 2.5vw;
    flex-direction: row;
    justify-content: space-between;
  }

  .sticky-item {
    display: flex;
    font-size: 4vw;
    padding: 1.5vw 0.5vw 1.5vw 0.5vw;
    /* box-shadow: 0 0 1px 1vw #79c000; */
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    width: 33vw;
    background: #79c000;
    border-radius: 19px;
    margin-left: 1vw;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 75%);
    border: none;
    justify-content: space-around;
  }

  .sticky-item:hover {
    color: #fff;
  }

  /* End Sticky Footer */
  /* ------------------- */
  /* Footer */
  footer .row {
    margin-left: 0.2vw;
  }

  footer {
    margin-bottom: 20vw;
  }

  /* social */

  .sponsor {
    padding-top: 0;
    padding-bottom: 3rem;
  }

  .methods {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .methods img {
    border: unset;
    height: auto;
    width: 10rem;
  }

  #legals img {
    width: 10vw;
    margin-left: 2vw;
    margin-right: 3vw;
  }

  .legalFooter > a {
    font-size: 1rem;
  }
}

.codere_desktop_game_title {
  color: #e8e5e5;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  font-size: 1.4vw;
  font-weight: 700;
  letter-spacing: 1px;
  flex-wrap: nowrap;
  align-items: center;
}

.top-bg-seo {
  padding-bottom: 17.5%;
}

.mainSeoPagesColombia {
  /* margin: 5rem 0 !important; */
  min-height: 40vw;
}
.mainSeoPagesColombia > h1 {
  color: #fff;
  margin-top: 15vw;
  text-align: center;
}

/* Footer */

hr {
  margin: auto;
  margin-top: 3vw;
  border-top: 4px solid #79c000;
  width: 55%;
}

footer.nb-footer .about {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 1170px;
  text-align: center;
}

footer.nb-footer .about .social-media {
  margin-top: 10px;
}

.sponsor {
  text-align: center;
  padding-top: 3rem;
}

.sponsor img {
  width: 9rem;
  margin: 4px;
}

.methods {
  text-align: center;
}

.methods img {
  /* width: 7rem; */
  height: 3rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-right: 3px solid #79c000;
}

.methods img:last-child {
  border-right: unset;
  width: 9rem;
  height: auto;
}

.list-inline {
  display: flex;
  gap: 15px;
  justify-content: center;
}

footer.nb-footer .about .social-media ul li a:hover {
  background: #fff;
  color: #fff;
}

footer.nb-footer .footer-info-single {
  margin-top: 30px;
}

footer.nb-footer .footer-info-single .title {
  color: #79c000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

footer.nb-footer .footer-info-single ul li a:hover {
  color: #79c000;
}

footer.nb-footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
}

.lazyloadimg {
  margin-top: 1.5rem;
  margin: auto;
  width: 100%;
}

.bottomImgLogo {
  max-width: 250px;
}
.bottomImgLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.txr90df45as {
  top: 18rem !important;
}
