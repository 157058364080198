.sport_games_slider {
  width: 75vw;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.sport_games_slider h1 {
  color: #79c000;
  font-size: 1.4rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.sport_games_slider {
  width: 100%;
  margin: 0 auto 2vw;
  text-align: center;
}

.startdate {
  font-size: 1.1rem !important;
  display: flex;
  width: 100%;
  padding: 0 15px;
  justify-content: space-between;
}

.frame {
  border-radius: 0.4px;
  background-color: #282828;
  width: 100%;
}

.framed {
  /* padding: 0 10px; */
}

.overflowed {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.url {
  color: #fff;
  text-decoration: none;
  /* max-width: 100% !important; */
  transition: all 250ms ease-in-out;
}

.url:hover {
  color: #79c000;
  transition: all 250ms ease-in-out;
}

.vs {
  width: 20%;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  --bs-gutter-x: 0;
  width: 100%;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.card {
  position: relative;
  display: flex;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.4) !important;
  background-clip: border-box;
  border-radius: 0.7rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
  max-width: 560px;
  margin: auto;
}

.card > div {
  width: 100%;
}

.arrows {
  position: relative;
  display: flex;
  justify-content: space-between;
  top: -125px;
}

.prev,
.next {
  /* top: -6vw; */
  font-size: 3rem;
  color: #fff;
  position: relative;
  cursor: pointer;
  opacity: 0.6;
  z-index: 1;
}

.prev {
  right: 2vw;
}

.next {
  left: 2vw;
}

@media screen and (max-width: 768px) {
  .sport_games_slider {
    font-size: 0.85rem;
    margin-bottom: 1rem;
  }

  .sport_games_slider h1 {
    font-size: calc(1.325rem + 0.9vw);
    margin-bottom: 0.75rem;
  }

  .card {
    border-radius: 0.4rem;
  }

  .row {
    width: 100%;
    margin-top: -10px;
  }

  .col {
    /* margin: 5px; */
    /* padding: 0 5px; */
  }

  .frame {
    /* width: 100% !important; */
    border-radius: 0.6rem;
    font-size: 0.92rem;
    /* padding: 5px 15px; */
  }
  .frame div {
    /* padding: 0 15px; */
    margin: 0 1.75vw;
  }

  .overflowed {
    max-width: 28vw;
    font-size: 3.45vw;
  }

  .framed {
    /* width: 90%; */
  }

  .arrows {
    top: -106px;
  }

  .prev,
  .next {
    font-size: 2rem;
  }

  .prev {
    /* margin-left: -10px; */
  }

  .next {
    /* margin-right: -10px; */
  }
}
